import React, { useEffect, useState, useRef } from "react";
import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/Navbar/Navbar";
import { HiUser } from "react-icons/hi";
import { LuDot } from "react-icons/lu";
import {
  IoIosArrowDropupCircle,
  IoIosArrowDropdownCircle,
} from "react-icons/io";
import { Link, useParams } from "react-router-dom";
import { ToastError, ToastSuccess } from "../../Components/utils/ToastMessages";
import { Services } from "../../Services";
import BlogClasses from "./Blogs.module.css";
import parse from "html-react-parser";
import { FallingLines } from "react-loader-spinner";
import { FaUser, FaArrowRight } from "react-icons/fa";
import { MdDateRange } from "react-icons/md";
import { useForm } from "react-hook-form";
import Seo from "./Seo";

function ReadMore() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [activeIndex, setActiveIndex] = useState(null);
  const [loading, setLoading] = useState(true);
  const [blog, setBlog] = useState({});
  const [similarBlogs, setSimilarBlogs] = useState([]);
  const { id } = useParams();
  const scrollRef = useRef(null);

  // Create refs for each section
  const sectionRefs = useRef({});

  useEffect(() => {
    if (id) {
      Services.GetBlogbyID("GET", null, id)
        .then((res) => {
          setLoading(false);
          if (res.Status === 1) {
            setBlog(res.blog);
            setSimilarBlogs(res.similarBlogs || []);
          } else if (res.Status === 0) {
            ToastError(res.Message);
          }
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
    }
  }, [id]);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  // const truncateText = (text, length) => {
  //   console.log('text.length', text.length)
  //   return text.length > length ? text.substring(0, length) + "..." : text;
  // };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: 350,
        behavior: "smooth",
      });
    }
  };

  const onSubmit = async (data) => {
    let body = { blogId: blog.blogId, ...data };
    try {
      const res = await Services.SaveReplyBlogs("POST", JSON.stringify(body));
      if (res.Status === 1) {
        ToastSuccess("Reply submitted successfully.");
        reset(); // Clear form after successful submission
      } else {
        ToastError(res.Message);
      }
    } catch (err) {
      console.error(err);
      ToastError("Something went wrong. Please try again.");
    }
  };

  const scrollToSection = (sectionId) => {
    sectionRefs.current[sectionId].scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div>
      <Navbar />
      <Seo
        metaTitle={blog.metaTitle}
        metaDescription={blog.metaDescription}
        metaKeywords={blog.metaKeywords}
      />

      <div className={BlogClasses["About-Img-container"]}>
        <div className={BlogClasses["About-container-img-text"]}>
          <div className={BlogClasses["About-hero-text"]}>
            <h1 className={BlogClasses["About-hero-text-h1"]}>Blogs</h1>
            <p className={BlogClasses["About-hero-text-p"]}>
              <Link to="/" className={BlogClasses["About-Home-link"]}>
                Home
              </Link>
              /
              <span className={BlogClasses["About-Home-link-span"]}>Blogs</span>
            </p>
          </div>
        </div>
      </div>

      {loading ? (
        <FallingLines
          color="#4fa94d"
          width="100"
          visible={true}
          ariaLabel="falling-circles-loading"
        />
      ) : (
        <>
          <div className={BlogClasses["readmore"]}>
            <div className={BlogClasses["readmore-container"]}>
              <h3 className={BlogClasses["readmore-heading"]}>
                {blog.blogTitle}
              </h3>

              <div className={BlogClasses["readmore-one-container"]}>
                <div className={BlogClasses["readmore-one-image"]}>
                  <img
                    src={blog.blogPhoto}
                    alt="Blog"
                    className={BlogClasses["readmore-img-name"]}
                  />
                </div>
                <div className={BlogClasses["readmore-one-content"]}>
                  <p className={BlogClasses["readmore-paragraph"]}>
                    {parse(blog.blogDescription)}
                  </p>
                </div>
              </div>
            </div>

            {/* Table of Contents */}
            <div className={BlogClasses["readmore-table-of-contents"]}>
              <div
                className={BlogClasses["readmore-table-of-contents-heading"]}
              >
                <h4>Table of Contents</h4>
              </div>
              <div className={BlogClasses["readmore-table-of-contents-list"]}>
                <ol>
                  {blog.sectionHeadings?.map((heading, index) => (
                    <li key={index}>
                      <div
                        onClick={() => scrollToSection(heading.sectionId)}
                        className={BlogClasses["toc-button"]}
                      >
                        {heading.heading}
                      </div>
                    </li>
                  ))}
                </ol>
              </div>
            </div>

            <div className={BlogClasses["readmore-two-container"]}>
              {blog.sections?.map((section) => (
                <div
                  key={section.sectionId}
                  id={`section-${section.sectionId}`}
                  className={BlogClasses["readmore-section"]}
                  ref={(el) => (sectionRefs.current[section.sectionId] = el)}
                >
                  <div className={BlogClasses["readmore-container"]}>
                    <div className={BlogClasses["readmore-text-container"]}>
                      <h2 className={BlogClasses["readmore-section-title"]}>
                        {section.title}
                      </h2>
                      <p className={BlogClasses["readmore-section-body"]}>
                        {section.body}
                      </p>
                    </div>
                    {section.photo && (
                      <div className={BlogClasses["readmore-image-container2"]}>
                        <img
                          src={section.photo}
                          alt={`Section ${section.sectionId}`}
                          className={BlogClasses["readmore-img-name"]}
                        />
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>

            <div className={BlogClasses["faq-container"]}>
              <h2>FAQs</h2>
              {blog.faqs?.map((faq, index) => (
                <div key={index} className={BlogClasses["faq-item"]}>
                  <div
                    className={BlogClasses["faq-question"]}
                    onClick={() => toggleFAQ(index)}
                  >
                    {faq.question}
                    <span className={BlogClasses["faq-arrow"]}>
                      {activeIndex === index ? (
                        <IoIosArrowDropupCircle
                          className={BlogClasses["faq-question-arrow-icon"]}
                        />
                      ) : (
                        <IoIosArrowDropdownCircle
                          className={BlogClasses["faq-question-arrow-icon"]}
                        />
                      )}
                    </span>
                  </div>

                  <div
                    className={`${BlogClasses["faq-answer"]} ${
                      activeIndex === index ? BlogClasses["open"] : ""
                    }`}
                  >
                    {faq.answer}
                  </div>
                </div>
              ))}
            </div>

            <div className={BlogClasses["free-immigration-assessment"]}>
              <h2>Leave a Reply</h2>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={BlogClasses["free-immigration"]}>
                  <div className={BlogClasses["free-immigration-form"]}>
                    <div className={BlogClasses["form-row"]}>
                      <input
                        type="text"
                        placeholder="NAME"
                        className={BlogClasses["free-immigration-input"]}
                        {...register("name", { required: "Name is Required" })}
                      />
                      {errors.name && <span>{errors.name.message}</span>}
                    </div>

                    <div className={BlogClasses["form-row"]}>
                      <select
                        className={BlogClasses["free-immigration-input"]}
                        {...register("website", {
                          required: "Visa is Required",
                        })}
                      >
                        <option value="">SELECT VISA</option>
                        <option value={1}>Full-time Student Visa</option>
                        <option value={2}>
                          Exchange/short-term Student Visa
                        </option>
                        <option value={3}>Research Student Visa</option>
                      </select>
                      {errors.visaType && (
                        <span>{errors.visaType.message}</span>
                      )}
                    </div>
                  </div>

                  <div className={BlogClasses["free-immigration-form"]}>
                    <div className={BlogClasses["form-row"]}>
                      <input
                        type="email"
                        placeholder="EMAIL"
                        className={BlogClasses["free-immigration-input"]}
                        {...register("email", {
                          required: "Email is Required",
                        })}
                      />
                      {errors.email && <span>{errors.email.message}</span>}
                    </div>

                    <div className="form-row">
                      <textarea
                        id="reply"
                        name="reply"
                        placeholder="REPLY"
                        className={BlogClasses["free-immigration-input"]}
                        {...register("reply", {
                          required: "Reply is Required",
                        })}
                      />
                      {errors.reply && <span>{errors.reply.message}</span>}
                    </div>
                    <div className={BlogClasses["from_button"]}>
                      <button type="submit">Submit</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          {/* <div className={BlogClasses["Similarblogs-list"]}>
            <h2 className={BlogClasses["Similarblogs-list-title"]}>
              Similar Blogs
            </h2>
            <div className={BlogClasses["Similarblogs-list-wrapper"]}>
              <div
                className={BlogClasses["Similarblogs-list-container"]}
                ref={scrollRef}
              >
                {similarBlogs.map((blog, index) => (
                  <div key={index} className={BlogClasses["Similarblogs-card"]}>
                    <img
                      src={blog.blogPhoto}
                      alt={blog.blogTitle}
                      className={BlogClasses["Similarblogs-card-image"]}
                    />
                    <div className={BlogClasses["Similarblogs-card-content"]}>
                      <div className={BlogClasses["Similarblogs-card-meta"]}>
                        <HiUser className={BlogClasses["Blog-card-icon"]} />
                        <span>{blog.author}</span>
                        <LuDot className={BlogClasses["Blog-card-icon"]} />
                        <span>
                          {new Date(blog.createdAt).toLocaleDateString()}
                        </span>
                      </div>
                      <div className={BlogClasses["Similarblogs-content"]}>
                        <h3 className={BlogClasses["Similarblogs-card-title"]}>
                          {blog.blogTitle}
                        </h3>
                        <p
                          className={
                            BlogClasses["Similarblogs-card-description"]
                          }
                        >
                          {parse(blog.blogDescription)}
                        </p>
                      </div>
                      <Link
                        to={`/Blogs/${blog.metaUrl}`}
                        className={BlogClasses["Similarblogs-card-read-more"]}
                      >
                        Read More →
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
              <button
                className={BlogClasses["scroll-arrow"]}
                onClick={scrollRight}
              >
                <FaArrowRight />
              </button>
            </div>
          </div> */}
          <div className={BlogClasses["Similarblogs-list"]}>
            <h2 className={BlogClasses["Similarblogs-list-title"]}>
              Similar Blogs
            </h2>
            <div className={BlogClasses["Similarblogs-list-wrapper"]}>
              <div
                className={BlogClasses["Similarblogs-list-container"]}
                ref={scrollRef}
              >
                {similarBlogs.length > 0 ? (
                  similarBlogs.map((blog, index) => (
                    <div
                      key={index}
                      className={BlogClasses["Similarblogs-card"]}
                    >
                      <img
                        src={blog.blogPhoto}
                        alt={blog.blogTitle}
                        className={BlogClasses["Similarblogs-card-image"]}
                      />
                      <div className={BlogClasses["Similarblogs-card-content"]}>
                        <div className={BlogClasses["Similarblogs-card-meta"]}>
                          <HiUser className={BlogClasses["Blog-card-icon"]} />
                          <span>{blog.author}</span>
                          <LuDot className={BlogClasses["Blog-card-icon"]} />
                          <span>
                            {new Date(blog.createdAt).toLocaleDateString()}
                          </span>
                        </div>
                        <div className={BlogClasses["Similarblogs-content"]}>
                          <h3
                            className={BlogClasses["Similarblogs-card-title"]}
                          >
                            {blog.blogTitle}
                          </h3>
                          <p
                            className={
                              BlogClasses["Similarblogs-card-description"]
                            }
                          >
                            {parse(blog.blogDescription)}
                          </p>
                        </div>
                        <Link
                          to={`/Blogs/${blog.metaUrl}`}
                          className={BlogClasses["Similarblogs-card-read-more"]}
                        >
                          Read More →
                        </Link>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className={BlogClasses["No-similar-blogs-message"]}>
                    No similar blogs found
                  </p>
                )}
              </div>
              {similarBlogs.length > 0 && (
                <button
                  className={BlogClasses["scroll-arrow"]}
                  onClick={scrollRight}
                >
                  <FaArrowRight />
                </button>
              )}
            </div>
          </div>
        </>
      )}
      <Footer />
    </div>
  );
}

export default ReadMore;
