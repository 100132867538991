import React, { useEffect, useState } from "react";
import { ToastError } from "../../Components/utils/ToastMessages";
import { Services } from "../../Services";
import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/Navbar/Navbar";
import { IoIosArrowRoundForward } from "react-icons/io";
import Pagination from "react-js-pagination";
import { HiUser } from "react-icons/hi";
import { LuDot } from "react-icons/lu";
import parse from "html-react-parser";
import BlogClasses from "./Blogs.module.css";
import { Link } from "react-router-dom";
import { IoSearch } from "react-icons/io5";

function Blogs() {
  const [blogs, setBlogs] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [totalPageCount, setPageCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const itemsPerPage = 9;

  useEffect(() => {
    getBlogs(activePage);
  }, [activePage]);

  useEffect(() => {
    setFilteredBlogs(
      blogs.filter((blog) =>
        blog.blogTitle.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery, blogs]);

  const getBlogs = async (page) => {
    try {
      const res = await Services.GetBlogs("GET", null, itemsPerPage, page);
      if (res.Status === 1) {
        setBlogs(res.blogs);
        setFilteredBlogs(res.blogs);
        setPageCount(res.pagination.totalBlogsCount);
      } else {
        ToastError(res.Error);
      }
    } catch (err) {
      ToastError("Something went wrong. Please try again.");
    }
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const truncateText = (text, length) => {
    return text.length > length ? text.substring(0, length) + "..." : text;
  };

  const Card = ({
    imageUrl,
    author,
    date,
    title,
    description,
    readMoreUrl,
  }) => {
    return (
      <div className={BlogClasses["Blog-card"]}>
        <div className={BlogClasses["Blog-card-image"]}>
          <img src={imageUrl} alt="Blogs" />
        </div>
        <div className={BlogClasses["Blog-card-content"]}>
          <div className={BlogClasses["Blog-card-details"]}>
            <HiUser className={BlogClasses["Blog-card-icon"]} />
            <span className={BlogClasses["Blog-card-author"]}>{author}</span>
            <LuDot className={BlogClasses["Blog-card-icon"]} />
            <span className={BlogClasses["Blog-card-date"]}>
              {formatDate(date)}
            </span>
          </div>
          <div className={BlogClasses["Blog-card-title-des"]}>
            <h3 className={BlogClasses["Blog-card-title"]}>
              {truncateText(title, 50)}
            </h3>
            <p className={BlogClasses["Blog-card-description"]}>
              {truncateText(parse(description), 50)}
            </p>
          </div>
          <Link to={readMoreUrl} className={BlogClasses["Blog-card-read-more"]}>
            Read More{" "}
            <IoIosArrowRoundForward className={BlogClasses["Blog-card-icon"]} />
          </Link>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Navbar />

      <div className={BlogClasses["About-Img-container"]}>
        <div className={BlogClasses["About-container-img-text"]}>
          <div className={BlogClasses["About-hero-text"]}>
            <h1 className={BlogClasses["About-hero-text-h1"]}>Blogs</h1>
            <p className={BlogClasses["About-hero-text-p"]}>
              <Link to="/" className={BlogClasses["About-Home-link"]}>
                Home
              </Link>
              /
              <span className={BlogClasses["About-Home-link-span"]}>Blogs</span>
            </p>
          </div>
        </div>
      </div>

      <div className={BlogClasses["Blogs-Container"]}>
        <div className={BlogClasses["search-container"]}>
          <input
            type="text"
            className={BlogClasses["search-input"]}
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <button className={BlogClasses["search-button"]}>
            <IoSearch className={BlogClasses["search-icon"]} />
            Search
          </button>
        </div>

        {filteredBlogs.length > 0 ? (
          <>
            <div className={BlogClasses["Blog-card-row"]}>
              {filteredBlogs.map((blog, index) => (
                <Card
                  key={index}
                  imageUrl={blog.blogPhoto}
                  author={blog.author}
                  date={blog.createdAt}
                  title={blog.blogTitle}
                  description={blog.blogDescription}
                  readMoreUrl={`/Blogs/${blog.metaUrl}`}
                  metaURL={blog.metaURL}
                />
              ))}
            </div>

            <div className={BlogClasses["pagination-container"]}>
              <Pagination
                activePage={activePage}
                itemsCountPerPage={itemsPerPage}
                totalItemsCount={totalPageCount}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                itemClass="page-item"
                linkClass="page-link"
              />
            </div>
          </>
        ) : (
          <p className={BlogClasses["no-blogs-message"]}>No blogs found.</p>
        )}
      </div>

      <Footer />
    </div>
  );
}

export default Blogs;
