export const ProgramLevel = [
  { label: "High School (11th - 12th)", value: 1 },
  {
    label: "UG Diploma/ Certificate/ Associate Degree",
    value: 2,
  },
  { label: "UG", value: 3 },
  { label: "PG Diploma/Certificate", value: 4 },
  { label: "PG", value: 5 },
  {
    label: "UG+PG (Accelerated) Degree",
    value: 6,
  },
  { label: "PhD", value: 7 },
  { label: "Foundation", value: 8 },
  { label: "Short Term Programs", value: 9 },
  { label: "Pathway Programs", value: 10 },
  { label: "Twinning Programmes (UG)", value: 11 },
  { label: "Twinning Programmes (PG)", value: 12 },
  { label: "English Language Program", value: 13 },
  {
    label: "Online Programmes / Distance Learning",
    value: 14,
  },
  {
    label: "Summer Programs",
    value: 15,
  },
];

export const Requirements = [
  { name: "pteRequired", label: "PTE", value: "PTE" },
  { name: "toeflRequired", label: "TOEFL iBT", value: "TOEFL iBT" },
  { name: "ieltsRequired", label: "IELTS", value: "IELTS" },
  { name: "detRequired", label: "DET", value: "DET" },
  { name: "satRequired", label: "SAT", value: "SAT" },
  { name: "actRequired", label: "ACT", value: "ACT" },
  { name: "greRequired", label: "GRE", value: "GRE" },
  { name: "gmatRequired", label: "GMAT", value: "GMAT" },
  {
    name: "withoutEnglishProficiency",
    label: "Without English Proficiency",
    value: "Without English Proficiency",
  },
  { name: "withoutGre", label: "Without GRE", value: "Without GRE" },
  { name: "withoutGmat", label: "Without GMAT", value: "Without GMAT" },
  { name: "withoutMaths", label: "Without Maths", value: "Without Maths" },
  { name: "stemCourses", label: "STEM Courses", value: "STEM Courses" },
  {
    name: "scholarshipAvailable",
    label: "Scholarship Available",
    value: "Scholarship Available",
  },
  {
    name: "withFifteenYearsOfEducation",
    label: "With 15 Years of Education",
    value: "With 15 Years of Education",
  },
  {
    name: "applicationFee",
    label: "Application Fee Waiver (upto 100%)",
    value: "Application Fee Waiver (upto 100%)",
  },
];

export const countries = [
  "United States of America",
  "Australia",
  "Canada",
  "United Kingdom",
  "New Zealand",
  "Singapore",
  "Dubai",
  "Ireland",
  "Germany",
  "France",
  "Sweden",
  "Netherlands",
  "Austria",
  "Denmark",
  "Finland",
  "Italy",
  "Hungary",
  "Switzerland",
  "Spain",
  "Lithuania",
  "Cyprus",
  "Poland",
  "Malaysia",
  "Mauritius",
  "China",
  "Vietnam",
  "Malta",
  "Japan",
  "Belgium",
  "Russia",
  "South Korea",
  "India",
  "Georgia",
  "Monaco",
];

export const dA = [
  {
    studyAreaId: 1,
    disciplineAreas: [
      {
        label: "Agribusiness",
        value: 1,
      },
      {
        label: "Agricultural Science",
        value: 2,
      },
      {
        label: "Agronomy",
        value: 3,
      },
      {
        label: "Fisheries ",
        value: 4,
      },
      {
        label: "Forestry",
        value: 5,
      },
      {
        label: "Horticulture",
        value: 6,
      },
      {
        label: "Mariculture",
        value: 7,
      },
      {
        label: "Poultry, Dairy",
        value: 8,
      },
    ],
  },
  {
    studyAreaId: 2,
    disciplineAreas: [
      {
        label: "Architecture",
        value: 9,
      },
      {
        label: "Construction",
        value: 10,
      },
      {
        label: "Construction Management",
        value: 11,
      },
      {
        label: "Heating",
        value: 12,
      },
      {
        label: "Interior Design",
        value: 13,
      },
      {
        label: "Surveying",
        value: 14,
      },
      {
        label: "Urban Planning",
        value: 15,
      },
      {
        label: "Ventilation ",
        value: 16,
      },
    ],
  },
  {
    studyAreaId: 3,
    disciplineAreas: [
      {
        label: "Animation",
        value: 17,
      },
      {
        label: "Applied Arts - Printing, Studio Art",
        value: 18,
      },
      {
        label: "Arts",
        value: 19,
      },
      {
        label: "Bakery and Pastry Arts",
        value: 20,
      },
      {
        label: "Car Interior Design ",
        value: 21,
      },
      {
        label:
          "Carpentry, Woodworking, Blacksmithing, Goldsmithing, Silversmithing, Ceramics",
        value: 22,
      },
      {
        label: "Creative Writing",
        value: 23,
      },
      {
        label: "Dance",
        value: 24,
      },
      {
        label: "Design - Fashion, Textile, Graphics, Product",
        value: 25,
      },
      {
        label: "English",
        value: 26,
      },
      {
        label: "Exhibition/Event",
        value: 27,
      },
      {
        label: "Film, Cinematography, Interactive Media, Multimedia",
        value: 28,
      },
      {
        label: "Fine Arts-Drawing, Painting, Sculpture",
        value: 29,
      },
      {
        label: "General Studies",
        value: 30,
      },
      {
        label: "Interdisciplinary Studies",
        value: 31,
      },
      {
        label: "Liberal Arts and Studies",
        value: 32,
      },
      {
        label: "Library",
        value: 33,
      },
      {
        label: "Music",
        value: 34,
      },
      {
        label: "Photography",
        value: 35,
      },
      {
        label: "Publishing",
        value: 36,
      },
      {
        label: "Theatre,Drama",
        value: 37,
      },
      {
        label: "Visual Arts",
        value: 38,
      },
    ],
  },
  {
    studyAreaId: 4,
    disciplineAreas: [
      {
        label: "Banking, Insurance, Risk Management, Taxation",
        value: 39,
      },
      {
        label: "Business Analytics, Management Consulting",
        value: 40,
      },
      {
        label:
          "Business Management, Business Administration, Entrepreneurship, Innovation, Operations, Project Mana",
        value: 41,
      },
      {
        label: "Commerce",
        value: 42,
      },
      {
        label: "Conflict Analysis and Management",
        value: 43,
      },
      {
        label: "Finance, Accounts, Commerce, Economics",
        value: 44,
      },
      {
        label:
          "Financial Management, International Management, Marketing Management, Technical Management",
        value: 45,
      },
      {
        label: "Hospitality and Tourism Management",
        value: 46,
      },
      {
        label: "Human Resource Management, Human Resource Development",
        value: 47,
      },
      {
        label: "Information Systems, Information Technology Management",
        value: 48,
      },
      {
        label: "International Business",
        value: 49,
      },
      {
        label: "Logistics, Supply Chain Management",
        value: 50,
      },
      {
        label: "Office Administration",
        value: 51,
      },
      {
        label: "Real Estate, Property Administeration",
        value: 52,
      },
      {
        label:
          "Sales, Marketing, Public Relations, Public Services, E-business, Digital Business, Digital Marketing",
        value: 53,
      },
    ],
  },
  {
    studyAreaId: 5,
    disciplineAreas: [
      {
        label: "Artificial Intelligence",
        value: 54,
      },
      {
        label: "Business Analysis, IT Business Analytics",
        value: 55,
      },
      {
        label: "Computer Information Systems",
        value: 56,
      },
      {
        label: "Computer Science, Software Development",
        value: 57,
      },
      {
        label: "Computer Technology",
        value: 58,
      },
      {
        label: "Computer/ Game Programming",
        value: 59,
      },
      {
        label: "Computing",
        value: 60,
      },
      {
        label: "Customer Intelligence and Analytics",
        value: 61,
      },
      {
        label: "Cyber Security, Information Security",
        value: 62,
      },
      {
        label: "Cytotechnology",
        value: 63,
      },
      {
        label: "Data Science and Analytics",
        value: 64,
      },
      {
        label: "Game Design",
        value: 65,
      },
      {
        label: "Information Technology",
        value: 66,
      },
      {
        label: "Mobile Application",
        value: 67,
      },
      {
        label: "Mobile Communication",
        value: 68,
      },
      {
        label: "Multimedia",
        value: 69,
      },
      {
        label: "Networking",
        value: 70,
      },
      {
        label: "Programmer",
        value: 71,
      },
      {
        label: "Software Testing",
        value: 72,
      },
      {
        label: "Web Design, Web Applications",
        value: 73,
      },
    ],
  },
  {
    studyAreaId: 6,
    disciplineAreas: [
      {
        label: "Adult, Organisational Learning and Leadership",
        value: 74,
      },
      {
        label:
          "Early Childhood Education, Primary Education ,Secondary Education",
        value: 75,
      },
      {
        label: "Education Assistance ",
        value: 76,
      },
      {
        label: "Education Counseling",
        value: 77,
      },
      {
        label: "Educational Training",
        value: 78,
      },
      {
        label: "Elementary Education",
        value: 79,
      },
      {
        label: "Gender Studies",
        value: 80,
      },
      {
        label: "Physical Education",
        value: 81,
      },
      {
        label: "Reading",
        value: 82,
      },
      {
        label: "Teacher Education Program",
        value: 83,
      },
    ],
  },
  {
    studyAreaId: 7,
    disciplineAreas: [
      {
        label: "Aeronautical, Aerospace Engineering, Aviation Technology",
        value: 84,
      },
      {
        label: "Applied Engineering",
        value: 85,
      },
      {
        label: "Architectural Engineering, Structural Engineering",
        value: 86,
      },
      {
        label: "Automotive Engineering",
        value: 87,
      },
      {
        label: "Aviation Technology",
        value: 88,
      },
      {
        label: "Biomedical Engineering ",
        value: 89,
      },
      {
        label: "Chemical Engineering",
        value: 90,
      },
      {
        label: "Civil Engineering, Geotechnical Engineering",
        value: 91,
      },
      {
        label: "Computer Engineering, Software Engineering",
        value: 92,
      },
      {
        label: "Electrical and Electronic Engineering",
        value: 93,
      },
      {
        label: "Electrical Engineering",
        value: 94,
      },
      {
        label: "Electromechanical Engineering",
        value: 95,
      },
      {
        label: "Electronics and Telecommunication Engineering",
        value: 96,
      },
      {
        label: "Electronics Engineering",
        value: 97,
      },
      {
        label: "Energy Systems Technology",
        value: 98,
      },
      {
        label: "Engineering Management",
        value: 99,
      },
      {
        label: "Environmental Engineering",
        value: 100,
      },
      {
        label: "Industrial Design",
        value: 101,
      },
      {
        label: "Industrial Engineering, Process Engineering",
        value: 102,
      },
      {
        label: "Instrumentation",
        value: 103,
      },
      {
        label: "Interdisciplinary Engineering",
        value: 104,
      },
      {
        label: "Liquid Crystal Engineering",
        value: 105,
      },
      {
        label: "Machine Learning",
        value: 106,
      },
      {
        label: "Manufacturing Engineering",
        value: 107,
      },
      {
        label: "Marine Engineering",
        value: 108,
      },
      {
        label: "Mechanical and Mechatronics Engineering",
        value: 109,
      },
      {
        label: "Mechanical Engineering",
        value: 110,
      },
      {
        label: "Mechatronics",
        value: 111,
      },
      {
        label: "Metallurgical Engineering, Material Engineering",
        value: 112,
      },
      {
        label: "Micro electro-mechanical systems (MEMS)",
        value: 113,
      },
      {
        label: "Mining Engineering",
        value: 114,
      },
      {
        label: "Nuclear Engineering",
        value: 115,
      },
      {
        label: "Petroleum Engineering",
        value: 116,
      },
      {
        label: "Plastics Engineering",
        value: 117,
      },
      {
        label: "Power Engineering ",
        value: 118,
      },
      {
        label: "Product Design Engineering",
        value: 119,
      },
      {
        label: "Robotics",
        value: 120,
      },
      {
        label: "Spatial Engineering",
        value: 121,
      },
      {
        label: "System Engineering",
        value: 122,
      },
      {
        label: "Technician",
        value: 123,
      },
      {
        label: "Telecommunications Engineering",
        value: 124,
      },
      {
        label: "Textile Engineering",
        value: 125,
      },
    ],
  },
  {
    studyAreaId: 8,
    disciplineAreas: [
      {
        label: "Environmental Science and Technology",
        value: 126,
      },
      {
        label: "Renewable Energy and Materials",
        value: 127,
      },
      {
        label: "Water Resources",
        value: 128,
      },
      {
        label: "Wild Life Ecosystem, Conservation",
        value: 129,
      },
    ],
  },
  {
    studyAreaId: 9,
    disciplineAreas: [
      {
        label: "Acupunture",
        value: 130,
      },
      {
        label: "Addiction and Mental Health",
        value: 131,
      },
      {
        label: "Audiology - Speech, Speech pathology, Speech Therapy",
        value: 132,
      },
      {
        label: "Autism",
        value: 133,
      },
      {
        label: "Biomechanical devices",
        value: 134,
      },
      {
        label: "Cardiovascular Science",
        value: 135,
      },
      {
        label: "Clinical Lab  ",
        value: 136,
      },
      {
        label: "Communicative Disorder",
        value: 137,
      },
      {
        label: "Dentistry, Dental Hygiene",
        value: 138,
      },
      {
        label: "Dietetics, Nutrition",
        value: 139,
      },
      {
        label: "Drug Development",
        value: 140,
      },
      {
        label: "Fitness, Physical Activity",
        value: 141,
      },
      {
        label: "Health Psychology",
        value: 142,
      },
      {
        label: "Health Science, Healthcare, Health Management",
        value: 143,
      },
      {
        label: "Kinesiology ",
        value: 144,
      },
      {
        label: "Medical Radiation Technology",
        value: 145,
      },
      {
        label: "Medical Science",
        value: 146,
      },
      {
        label: "Medical Technology ",
        value: 147,
      },
      {
        label: "Medicine",
        value: 148,
      },
      {
        label: "Midwifery",
        value: 149,
      },
      {
        label: "Nursing",
        value: 150,
      },
      {
        label: "Occupational Therapy",
        value: 151,
      },
      {
        label: "Optometry",
        value: 152,
      },
      {
        label: "Paradmeic Studies ",
        value: 153,
      },
      {
        label: "Pharmaceutical Sciences, Pharmacy",
        value: 154,
      },
      {
        label: "Physical Therapy, Physiotherapy",
        value: 155,
      },
      {
        label: "Psychiatric Nursing",
        value: 156,
      },
      {
        label: "Psychology",
        value: 157,
      },
      {
        label: "Public Heath",
        value: 158,
      },
      {
        label: "Radiologic Science",
        value: 159,
      },
      {
        label: "Recreation",
        value: 160,
      },
      {
        label: "Rehabilitation Assistant",
        value: 161,
      },
      {
        label: "Respiratory Care",
        value: 162,
      },
      {
        label: "Therapeutic Recreation",
        value: 163,
      },
      {
        label: "Therapist Assistant",
        value: 164,
      },
    ],
  },
  {
    studyAreaId: 10,
    disciplineAreas: [
      {
        label: "Geography",
        value: 165,
      },
      {
        label: "History",
        value: 166,
      },
      {
        label: "Humanities",
        value: 167,
      },
      {
        label: "Indigenous Studies",
        value: 168,
      },
      {
        label: "Language and Literature",
        value: 169,
      },
      {
        label: "Museum and Gallery Studies",
        value: 170,
      },
      {
        label: "Philosophy, Aesthetics",
        value: 171,
      },
      {
        label: "Religious Studies",
        value: 172,
      },
      {
        label: "Theology",
        value: 173,
      },
    ],
  },
  {
    studyAreaId: 11,
    disciplineAreas: [
      {
        label: "Advertising",
        value: 174,
      },
      {
        label: "Journalism, Broadcasting Journalism",
        value: 175,
      },
      {
        label: "Media, Mass communication, Technical comunication",
        value: 176,
      },
    ],
  },
  {
    studyAreaId: 12,
    disciplineAreas: [
      {
        label: "Criminology",
        value: 177,
      },
      {
        label: "Forensic Science",
        value: 178,
      },
      {
        label: "Justice and Emergency Services",
        value: 179,
      },
      {
        label: "Law, LLB, LLM",
        value: 180,
      },
      {
        label: "Legal Assistant, Court Support",
        value: 181,
      },
    ],
  },
  {
    studyAreaId: 13,
    disciplineAreas: [
      {
        label: "Biochemistry",
        value: 182,
      },
      {
        label: "Bioinformatics",
        value: 183,
      },
      {
        label: "Biology, Biological Science",
        value: 184,
      },
      {
        label: "Biomaterials",
        value: 185,
      },
      {
        label: "Biomedical Sciences",
        value: 186,
      },
      {
        label: "Biotechnology",
        value: 187,
      },
      {
        label: "Clinical Sciences",
        value: 188,
      },
      {
        label:
          "Food Science, Food Science and Nutrition, Food Science and Technology",
        value: 189,
      },
      {
        label: "Genetics",
        value: 190,
      },
      {
        label: "Hydrobiology",
        value: 191,
      },
      {
        label: "Immunology",
        value: 192,
      },
      {
        label: "Life Science",
        value: 193,
      },
      {
        label: "Marine Biology",
        value: 194,
      },
      {
        label: "Microbiology",
        value: 195,
      },
      {
        label: "Neuroscience",
        value: 196,
      },
      {
        label: "Oral Science",
        value: 197,
      },
      {
        label: "Physiology",
        value: 198,
      },
      {
        label: "Plant Taxanomy , Plant Science",
        value: 199,
      },
      {
        label: "Zoology",
        value: 200,
      },
    ],
  },
  {
    studyAreaId: 14,
    disciplineAreas: [
      {
        label: "Material Science, Material Science and Engineering",
        value: 201,
      },
      {
        label: "Mining",
        value: 202,
      },
      {
        label: "Paper and Bioprocess",
        value: 203,
      },
      {
        label: "Petroleum, Oil and Gas, Diesel Technology",
        value: 204,
      },
      {
        label: "Supply Chain Management",
        value: 205,
      },
      {
        label: "Textiles ",
        value: 206,
      },
      {
        label: "Welding and Fabrication",
        value: 207,
      },
      {
        label: "Wine Making, Brewery, Winery",
        value: 208,
      },
    ],
  },
  {
    studyAreaId: 15,
    disciplineAreas: [
      {
        label: "Actuarial Science",
        value: 209,
      },
      {
        label: "Mathematics",
        value: 210,
      },
      {
        label: "Statistics",
        value: 211,
      },
    ],
  },
  {
    studyAreaId: 16,
    disciplineAreas: [
      {
        label: "Aviation",
        value: 212,
      },
      {
        label: "Culinary Skills ",
        value: 213,
      },
      {
        label: "Financial Services",
        value: 214,
      },
      {
        label: "Hair Styling, Makeup, Cosmetics, Esthetic Services",
        value: 215,
      },
      {
        label: "Hospitality, Culinary Arts, Travel and Tourism",
        value: 216,
      },
      {
        label: "Massage Therapy",
        value: 217,
      },
      {
        label: "Sports Science, Sports, Sports Management, Exercise",
        value: 218,
      },
    ],
  },
  {
    studyAreaId: 17,
    disciplineAreas: [
      {
        label: "Astronomy, Astrophysics",
        value: 219,
      },
      {
        label: "Atmospheric Sciences",
        value: 220,
      },
      {
        label: "Chemistry",
        value: 221,
      },
      {
        label: "Earth Sciences",
        value: 222,
      },
      {
        label: "Geography",
        value: 223,
      },
      {
        label: "Geology",
        value: 224,
      },
      {
        label: "Geology",
        value: 225,
      },
      {
        label: "Geophysics",
        value: 226,
      },
      {
        label: "Geospatial Science",
        value: 227,
      },
      {
        label: "Lab Technician",
        value: 228,
      },
      {
        label: "Meteorology",
        value: 229,
      },
      {
        label: "Nanoscience, Nanotechnology",
        value: 230,
      },
      {
        label: "Physics",
        value: 231,
      },
      {
        label: "Science",
        value: 232,
      },
    ],
  },
  {
    studyAreaId: 18,
    disciplineAreas: [
      {
        label: "Criminal Science, International Criminology",
        value: 233,
      },
      {
        label: "Fire Science, firefighting, Fire and Safety",
        value: 234,
      },
      {
        label: "Forensic Science",
        value: 235,
      },
      {
        label: "Military",
        value: 236,
      },
      {
        label: "Safety, Police and Public Safety ",
        value: 237,
      },
    ],
  },
  {
    studyAreaId: 19,
    disciplineAreas: [
      {
        label: "Anthropology",
        value: 238,
      },
      {
        label: "Archaeology",
        value: 239,
      },
      {
        label: "Behavioral Sciences",
        value: 240,
      },
      {
        label: "Cultural studies, Inter-cultural communication",
        value: 241,
      },
      {
        label: "Economics",
        value: 242,
      },
      {
        label: "Ethnic Studies",
        value: 243,
      },
      {
        label: "Gerontology",
        value: 244,
      },
      {
        label: "Human Ecology, Ecology",
        value: 245,
      },
      {
        label: "Human Service",
        value: 246,
      },
      {
        label: "International Relations",
        value: 247,
      },
      {
        label: "Political Science",
        value: 248,
      },
      {
        label: "Psychology",
        value: 249,
      },
      {
        label: "Social Policy",
        value: 250,
      },
      {
        label: "Sociology, Social Science",
        value: 251,
      },
    ],
  },
  {
    studyAreaId: 20,
    disciplineAreas: [
      {
        label:
          "Child Care, Child and Youth Worker, Child Care Development and Studies",
        value: 252,
      },
      {
        label: "Community Development",
        value: 253,
      },
      {
        label: "Developmental Service Worker",
        value: 254,
      },
      {
        label: "Personal Support Work ",
        value: 255,
      },
      {
        label: "Social Work and NGO Related Fields",
        value: 256,
      },
    ],
  },
  {
    studyAreaId: 21,
    disciplineAreas: [
      {
        label: "Transportation, Transportation Management",
        value: 257,
      },
    ],
  },
  {
    studyAreaId: 22,
    disciplineAreas: [
      {
        label: "Animal Care ",
        value: 258,
      },
      {
        label: "Animal Conservation ",
        value: 259,
      },
      {
        label: "Animal Science",
        value: 260,
      },
      {
        label: "Veterinary Science",
        value: 261,
      },
    ],
  },
];

export const studyAreas = [
  "Agriculture, Forestry and Fishery",
  "Architecture and Building",
  "Arts",
  "Commerce, Business and Administration",
  "Computer science and Information Technology",
  "Education",
  "Engineering and Engineering Trades",
  "Environmental Science / protection",
  "Health",
  "Humanities",
  "Journalism and Information",
  "Law",
  "Life Sciences",
  "Manufacturing and Processing",
  "Mathematics and Statistics",
  "Personal Services",
  "Physical Sciences, Sciences",
  "Security Services",
  "Social and Behavioural Science",
  "Social Services",
  "Transport Services",
  "Veterinary",
];

export const disciplineAreas = [
  {
    area: "Agriculture, Forestry and Fishery",
    options: [
      {
        label: "Agribusiness",
        value: 1,
      },
      {
        label: "Agricultural Science",
        value: 2,
      },
      {
        label: "Agronomy",
        value: 3,
      },
      {
        label: "Fisheries ",
        value: 4,
      },
      {
        label: "Forestry",
        value: 5,
      },
      {
        label: "Horticulture",
        value: 6,
      },
      {
        label: "Mariculture",
        value: 7,
      },
      {
        label: "Poultry, Dairy",
        value: 8,
      },
    ],
  },
  {
    area: "Architecture and Building",
    options: [
      {
        label: "Architecture",
        value: 9,
      },
      {
        label: "Construction",
        value: 10,
      },
      {
        label: "Construction Management",
        value: 11,
      },
      {
        label: "Heating",
        value: 12,
      },
      {
        label: "Interior Design",
        value: 13,
      },
      {
        label: "Surveying",
        value: 14,
      },
      {
        label: "Urban Planning",
        value: 15,
      },
      {
        label: "Ventilation ",
        value: 16,
      },
    ],
  },
  {
    area: "Arts",
    options: [
      {
        label: "Animation",
        value: 17,
      },
      {
        label: "Applied Arts - Printing, Studio Art",
        value: 18,
      },
      {
        label: "Arts",
        value: 19,
      },
      {
        label: "Bakery and Pastry Arts",
        value: 20,
      },
      {
        label: "Car Interior Design ",
        value: 21,
      },
      {
        label:
          "Carpentry, Woodworking, Blacksmithing, Goldsmithing, Silversmithing, Ceramics",
        value: 22,
      },
      {
        label: "Creative Writing",
        value: 23,
      },
      {
        label: "Dance",
        value: 24,
      },
      {
        label: "Design - Fashion, Textile, Graphics, Product",
        value: 25,
      },
      {
        label: "English",
        value: 26,
      },
      {
        label: "Exhibition/Event",
        value: 27,
      },
      {
        label: "Film, Cinematography, Interactive Media, Multimedia",
        value: 28,
      },
      {
        label: "Fine Arts-Drawing, Painting, Sculpture",
        value: 29,
      },
      {
        label: "General Studies",
        value: 30,
      },
      {
        label: "Interdisciplinary Studies",
        value: 31,
      },
      {
        label: "Liberal Arts and Studies",
        value: 32,
      },
      {
        label: "Library",
        value: 33,
      },
      {
        label: "Music",
        value: 34,
      },
      {
        label: "Photography",
        value: 35,
      },
      {
        label: "Publishing",
        value: 36,
      },
      {
        label: "Theatre,Drama",
        value: 37,
      },
      {
        label: "Visual Arts",
        value: 38,
      },
    ],
  },
  {
    area: "Commerce, Business and Administration",
    options: [
      {
        label: "Banking, Insurance, Risk Management, Taxation",
        value: 39,
      },
      {
        label: "Business Analytics, Management Consulting",
        value: 40,
      },
      {
        label:
          "Business Management, Business Administration, Entrepreneurship, Innovation, Operations, Project Mana",
        value: 41,
      },
      {
        label: "Commerce",
        value: 42,
      },
      {
        label: "Conflict Analysis and Management",
        value: 43,
      },
      {
        label: "Finance, Accounts, Commerce, Economics",
        value: 44,
      },
      {
        label:
          "Financial Management, International Management, Marketing Management, Technical Management",
        value: 45,
      },
      {
        label: "Hospitality and Tourism Management",
        value: 46,
      },
      {
        label: "Human Resource Management, Human Resource Development",
        value: 47,
      },
      {
        label: "Information Systems, Information Technology Management",
        value: 48,
      },
      {
        label: "International Business",
        value: 49,
      },
      {
        label: "Logistics, Supply Chain Management",
        value: 50,
      },
      {
        label: "Office Administration",
        value: 51,
      },
      {
        label: "Real Estate, Property Administeration",
        value: 52,
      },
      {
        label:
          "Sales, Marketing, Public Relations, Public Services, E-business, Digital Business, Digital Marketing",
        value: 53,
      },
    ],
  },
  {
    area: "Computer science and Information Technology",
    options: [
      {
        label: "Artificial Intelligence",
        value: 54,
      },
      {
        label: "Business Analysis, IT Business Analytics",
        value: 55,
      },
      {
        label: "Computer Information Systems",
        value: 56,
      },
      {
        label: "Computer Science, Software Development",
        value: 57,
      },
      {
        label: "Computer Technology",
        value: 58,
      },
      {
        label: "Computer/ Game Programming",
        value: 59,
      },
      {
        label: "Computing",
        value: 60,
      },
      {
        label: "Customer Intelligence and Analytics",
        value: 61,
      },
      {
        label: "Cyber Security, Information Security",
        value: 62,
      },
      {
        label: "Cytotechnology",
        value: 63,
      },
      {
        label: "Data Science and Analytics",
        value: 64,
      },
      {
        label: "Game Design",
        value: 65,
      },
      {
        label: "Information Technology",
        value: 66,
      },
      {
        label: "Mobile Application",
        value: 67,
      },
      {
        label: "Mobile Communication",
        value: 68,
      },
      {
        label: "Multimedia",
        value: 69,
      },
      {
        label: "Networking",
        value: 70,
      },
      {
        label: "Programmer",
        value: 71,
      },
      {
        label: "Software Testing",
        value: 72,
      },
      {
        label: "Web Design, Web Applications",
        value: 73,
      },
    ],
  },
  {
    area: "Education",
    options: [
      {
        label: "Adult, Organisational Learning and Leadership",
        value: 74,
      },
      {
        label:
          "Early Childhood Education, Primary Education ,Secondary Education",
        value: 75,
      },
      {
        label: "Education Assistance ",
        value: 76,
      },
      {
        label: "Education Counseling",
        value: 77,
      },
      {
        label: "Educational Training",
        value: 78,
      },
      {
        label: "Elementary Education",
        value: 79,
      },
      {
        label: "Gender Studies",
        value: 80,
      },
      {
        label: "Physical Education",
        value: 81,
      },
      {
        label: "Reading",
        value: 82,
      },
      {
        label: "Teacher Education Program",
        value: 83,
      },
    ],
  },
  {
    area: "Engineering and Engineering Trades",
    options: [
      {
        label: "Aeronautical, Aerospace Engineering, Aviation Technology",
        value: 84,
      },
      {
        label: "Applied Engineering",
        value: 85,
      },
      {
        label: "Architectural Engineering, Structural Engineering",
        value: 86,
      },
      {
        label: "Automotive Engineering",
        value: 87,
      },
      {
        label: "Aviation Technology",
        value: 88,
      },
      {
        label: "Biomedical Engineering ",
        value: 89,
      },
      {
        label: "Chemical Engineering",
        value: 90,
      },
      {
        label: "Civil Engineering, Geotechnical Engineering",
        value: 91,
      },
      {
        label: "Computer Engineering, Software Engineering",
        value: 92,
      },
      {
        label: "Electrical and Electronic Engineering",
        value: 93,
      },
      {
        label: "Electrical Engineering",
        value: 94,
      },
      {
        label: "Electromechanical Engineering",
        value: 95,
      },
      {
        label: "Electronics and Telecommunication Engineering",
        value: 96,
      },
      {
        label: "Electronics Engineering",
        value: 97,
      },
      {
        label: "Energy Systems Technology",
        value: 98,
      },
      {
        label: "Engineering Management",
        value: 99,
      },
      {
        label: "Environmental Engineering",
        value: 100,
      },
      {
        label: "Industrial Design",
        value: 101,
      },
      {
        label: "Industrial Engineering, Process Engineering",
        value: 102,
      },
      {
        label: "Instrumentation",
        value: 103,
      },
      {
        label: "Interdisciplinary Engineering",
        value: 104,
      },
      {
        label: "Liquid Crystal Engineering",
        value: 105,
      },
      {
        label: "Machine Learning",
        value: 106,
      },
      {
        label: "Manufacturing Engineering",
        value: 107,
      },
      {
        label: "Marine Engineering",
        value: 108,
      },
      {
        label: "Mechanical and Mechatronics Engineering",
        value: 109,
      },
      {
        label: "Mechanical Engineering",
        value: 110,
      },
      {
        label: "Mechatronics",
        value: 111,
      },
      {
        label: "Metallurgical Engineering, Material Engineering",
        value: 112,
      },
      {
        label: "Micro electro-mechanical systems (MEMS)",
        value: 113,
      },
      {
        label: "Mining Engineering",
        value: 114,
      },
      {
        label: "Nuclear Engineering",
        value: 115,
      },
      {
        label: "Petroleum Engineering",
        value: 116,
      },
      {
        label: "Plastics Engineering",
        value: 117,
      },
      {
        label: "Power Engineering ",
        value: 118,
      },
      {
        label: "Product Design Engineering",
        value: 119,
      },
      {
        label: "Robotics",
        value: 120,
      },
      {
        label: "Spatial Engineering",
        value: 121,
      },
      {
        label: "System Engineering",
        value: 122,
      },
      {
        label: "Technician",
        value: 123,
      },
      {
        label: "Telecommunications Engineering",
        value: 124,
      },
      {
        label: "Textile Engineering",
        value: 125,
      },
    ],
  },
  {
    area: "Environmental Science / protection",
    options: [
      {
        label: "Environmental Science and Technology",
        value: 126,
      },
      {
        label: "Renewable Energy and Materials",
        value: 127,
      },
      {
        label: "Water Resources",
        value: 128,
      },
      {
        label: "Wild Life Ecosystem, Conservation",
        value: 129,
      },
    ],
  },
  {
    area: "Health",
    options: [
      {
        label: "Acupunture",
        value: 130,
      },
      {
        label: "Addiction and Mental Health",
        value: 131,
      },
      {
        label: "Audiology - Speech, Speech pathology, Speech Therapy",
        value: 132,
      },
      {
        label: "Autism",
        value: 133,
      },
      {
        label: "Biomechanical devices",
        value: 134,
      },
      {
        label: "Cardiovascular Science",
        value: 135,
      },
      {
        label: "Clinical Lab  ",
        value: 136,
      },
      {
        label: "Communicative Disorder",
        value: 137,
      },
      {
        label: "Dentistry, Dental Hygiene",
        value: 138,
      },
      {
        label: "Dietetics, Nutrition",
        value: 139,
      },
      {
        label: "Drug Development",
        value: 140,
      },
      {
        label: "Fitness, Physical Activity",
        value: 141,
      },
      {
        label: "Health Psychology",
        value: 142,
      },
      {
        label: "Health Science, Healthcare, Health Management",
        value: 143,
      },
      {
        label: "Kinesiology ",
        value: 144,
      },
      {
        label: "Medical Radiation Technology",
        value: 145,
      },
      {
        label: "Medical Science",
        value: 146,
      },
      {
        label: "Medical Technology ",
        value: 147,
      },
      {
        label: "Medicine",
        value: 148,
      },
      {
        label: "Midwifery",
        value: 149,
      },
      {
        label: "Nursing",
        value: 150,
      },
      {
        label: "Occupational Therapy",
        value: 151,
      },
      {
        label: "Optometry",
        value: 152,
      },
      {
        label: "Paradmeic Studies ",
        value: 153,
      },
      {
        label: "Pharmaceutical Sciences, Pharmacy",
        value: 154,
      },
      {
        label: "Physical Therapy, Physiotherapy",
        value: 155,
      },
      {
        label: "Psychiatric Nursing",
        value: 156,
      },
      {
        label: "Psychology",
        value: 157,
      },
      {
        label: "Public Heath",
        value: 158,
      },
      {
        label: "Radiologic Science",
        value: 159,
      },
      {
        label: "Recreation",
        value: 160,
      },
      {
        label: "Rehabilitation Assistant",
        value: 161,
      },
      {
        label: "Respiratory Care",
        value: 162,
      },
      {
        label: "Therapeutic Recreation",
        value: 163,
      },
      {
        label: "Therapist Assistant",
        value: 164,
      },
    ],
  },
  {
    area: "Humanities",
    options: [
      {
        label: "Geography",
        value: 165,
      },
      {
        label: "History",
        value: 166,
      },
      {
        label: "Humanities",
        value: 167,
      },
      {
        label: "Indigenous Studies",
        value: 168,
      },
      {
        label: "Language and Literature",
        value: 169,
      },
      {
        label: "Museum and Gallery Studies",
        value: 170,
      },
      {
        label: "Philosophy, Aesthetics",
        value: 171,
      },
      {
        label: "Religious Studies",
        value: 172,
      },
      {
        label: "Theology",
        value: 173,
      },
    ],
  },
  {
    area: "Journalism and Information",
    options: [
      {
        label: "Advertising",
        value: 174,
      },
      {
        label: "Journalism, Broadcasting Journalism",
        value: 175,
      },
      {
        label: "Media, Mass communication, Technical comunication",
        value: 176,
      },
    ],
  },
  {
    area: "Law",
    options: [
      {
        label: "Criminology",
        value: 177,
      },
      {
        label: "Forensic Science",
        value: 178,
      },
      {
        label: "Justice and Emergency Services",
        value: 179,
      },
      {
        label: "Law, LLB, LLM",
        value: 180,
      },
      {
        label: "Legal Assistant, Court Support",
        value: 181,
      },
    ],
  },
  {
    area: "Life Sciences",
    options: [
      {
        label: "Biochemistry",
        value: 182,
      },
      {
        label: "Bioinformatics",
        value: 183,
      },
      {
        label: "Biology, Biological Science",
        value: 184,
      },
      {
        label: "Biomaterials",
        value: 185,
      },
      {
        label: "Biomedical Sciences",
        value: 186,
      },
      {
        label: "Biotechnology",
        value: 187,
      },
      {
        label: "Clinical Sciences",
        value: 188,
      },
      {
        label:
          "Food Science, Food Science and Nutrition, Food Science and Technology",
        value: 189,
      },
      {
        label: "Genetics",
        value: 190,
      },
      {
        label: "Hydrobiology",
        value: 191,
      },
      {
        label: "Immunology",
        value: 192,
      },
      {
        label: "Life Science",
        value: 193,
      },
      {
        label: "Marine Biology",
        value: 194,
      },
      {
        label: "Microbiology",
        value: 195,
      },
      {
        label: "Neuroscience",
        value: 196,
      },
      {
        label: "Oral Science",
        value: 197,
      },
      {
        label: "Physiology",
        value: 198,
      },
      {
        label: "Plant Taxanomy , Plant Science",
        value: 199,
      },
      {
        label: "Zoology",
        value: 200,
      },
    ],
  },
  {
    area: "Manufacturing and Processing",
    options: [
      {
        label: "Material Science, Material Science and Engineering",
        value: 201,
      },
      {
        label: "Mining",
        value: 202,
      },
      {
        label: "Paper and Bioprocess",
        value: 203,
      },
      {
        label: "Petroleum, Oil and Gas, Diesel Technology",
        value: 204,
      },
      {
        label: "Supply Chain Management",
        value: 205,
      },
      {
        label: "Textiles ",
        value: 206,
      },
      {
        label: "Welding and Fabrication",
        value: 207,
      },
      {
        label: "Wine Making, Brewery, Winery",
        value: 208,
      },
    ],
  },
  {
    area: "Mathematics and Statistics",
    options: [
      {
        label: "Actuarial Science",
        value: 209,
      },
      {
        label: "Mathematics",
        value: 210,
      },
      {
        label: "Statistics",
        value: 211,
      },
    ],
  },
  {
    area: "Personal Services",
    options: [
      {
        label: "Aviation",
        value: 212,
      },
      {
        label: "Culinary Skills ",
        value: 213,
      },
      {
        label: "Financial Services",
        value: 214,
      },
      {
        label: "Hair Styling, Makeup, Cosmetics, Esthetic Services",
        value: 215,
      },
      {
        label: "Hospitality, Culinary Arts, Travel and Tourism",
        value: 216,
      },
      {
        label: "Massage Therapy",
        value: 217,
      },
      {
        label: "Sports Science, Sports, Sports Management, Exercise",
        value: 218,
      },
    ],
  },
  {
    area: "Physical Sciences, Sciences",
    options: [
      {
        label: "Astronomy, Astrophysics",
        value: 219,
      },
      {
        label: "Atmospheric Sciences",
        value: 220,
      },
      {
        label: "Chemistry",
        value: 221,
      },
      {
        label: "Earth Sciences",
        value: 222,
      },
      {
        label: "Geography",
        value: 223,
      },
      {
        label: "Geology",
        value: 224,
      },
      {
        label: "Geology",
        value: 225,
      },
      {
        label: "Geophysics",
        value: 226,
      },
      {
        label: "Geospatial Science",
        value: 227,
      },
      {
        label: "Lab Technician",
        value: 228,
      },
      {
        label: "Meteorology",
        value: 229,
      },
      {
        label: "Nanoscience, Nanotechnology",
        value: 230,
      },
      {
        label: "Physics",
        value: 231,
      },
      {
        label: "Science",
        value: 232,
      },
    ],
  },
  {
    area: "Security Services",
    options: [
      {
        label: "Criminal Science, International Criminology",
        value: 233,
      },
      {
        label: "Fire Science, firefighting, Fire and Safety",
        value: 234,
      },
      {
        label: "Forensic Science",
        value: 235,
      },
      {
        label: "Military",
        value: 236,
      },
      {
        label: "Safety, Police and Public Safety ",
        value: 237,
      },
    ],
  },
  {
    area: "Social and Behavioural Science",
    options: [
      {
        label: "Anthropology",
        value: 238,
      },
      {
        label: "Archaeology",
        value: 239,
      },
      {
        label: "Behavioral Sciences",
        value: 240,
      },
      {
        label: "Cultural studies, Inter-cultural communication",
        value: 241,
      },
      {
        label: "Economics",
        value: 242,
      },
      {
        label: "Ethnic Studies",
        value: 243,
      },
      {
        label: "Gerontology",
        value: 244,
      },
      {
        label: "Human Ecology, Ecology",
        value: 245,
      },
      {
        label: "Human Service",
        value: 246,
      },
      {
        label: "International Relations",
        value: 247,
      },
      {
        label: "Political Science",
        value: 248,
      },
      {
        label: "Psychology",
        value: 249,
      },
      {
        label: "Social Policy",
        value: 250,
      },
      {
        label: "Sociology, Social Science",
        value: 251,
      },
    ],
  },
  {
    area: "Social Services",
    options: [
      {
        label:
          "Child Care, Child and Youth Worker, Child Care Development and Studies",
        value: 252,
      },
      {
        label: "Community Development",
        value: 253,
      },
      {
        label: "Developmental Service Worker",
        value: 254,
      },
      {
        label: "Personal Support Work ",
        value: 255,
      },
      {
        label: "Social Work and NGO Related Fields",
        value: 256,
      },
    ],
  },
  {
    area: "Transport Services",
    options: [
      {
        label: "Transportation, Transportation Management",
        value: 257,
      },
    ],
  },
  {
    area: "Veterinary",
    options: [
      {
        label: "Animal Care ",
        value: 258,
      },
      {
        label: "Animal Conservation ",
        value: 259,
      },
      {
        label: "Animal Science",
        value: 260,
      },
      {
        label: "Veterinary Science",
        value: 261,
      },
    ],
  },
];

export const Duration = [
  {
    value: [0, 12],
    label: "0 to 1 Years",
  },
  {
    value: [12, 24],
    label: "1 to 2 Years",
  },
  {
    value: [24.36],
    label: "2 to 3 Years",
  },
  {
    value: [36, 48],
    label: "3 to 4 Years",
  },
  {
    value: [48, -1],
    label: "4 and Above Years",
  },
];

export const ESLAvailable = [
  {
    value: 1,
    label: "Yes",
  },
  {
    value: 0,
    label: "No",
  },
];