import "rc-slider/assets/index.css";

import { FaFilter, FaPlus } from "react-icons/fa6";
import React, { useEffect, useState } from "react";
import AdvanceClasses from "./AdvanceSearch.module.css";
import { IoIosArrowDown } from "react-icons/io";
import Modal from "react-modal";
import Select from "react-select";
import Slider from "rc-slider";
import countryList from "react-select-country-list";

function HomeFilter({ results, filteredResults, setFilteredResults }) {
  const countryOptions = countryList().getData();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [universities, setUniversities] = useState([]);
  const [programLevels, setProgramLevels] = useState([]);

  const [selectedTuitionRange, setSelectedTuitionRange] = useState([0, 100000]);
  const [selectedUniversities, setSelectedUniversities] = useState([]);
  const [selectedProgramLevels, setSelectedProgramLevels] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({});

  const handleUniversityCheck = (data) => {
    const id = data.target.id;
    const checked = data.target.checked;

    checked
      ? setSelectedUniversities([parseInt(id), ...selectedUniversities])
      : setSelectedUniversities(
          selectedUniversities.filter((a) => a !== parseInt(id))
        );
  };

  const handleProgramLevelCheck = (data) => {
    const id = data.target.id;
    const checked = data.target.checked;

    checked
      ? setSelectedProgramLevels([parseInt(id), ...selectedProgramLevels])
      : setSelectedProgramLevels(
          selectedProgramLevels.filter((a) => a !== parseInt(id))
        );
  };

  const handleLocationChange = (data) => {
    setSelectedLocation(data);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [formData, setFormData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    mobileNumber: "",
    emailAddress: "",
  });

  // Function to handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsModalOpen(false);

    setFormData({
      firstName: "",
      middleName: "",
      lastName: "",
      mobileNumber: "",
      emailAddress: "",
    });
  };

  const openModal = () => {
    setIsModalOpen(true);
    setIsDropdownOpen(false);
  };

  const calculateFilters = (results) => {
    const collegesInfo = {};
    const distinctCollegeInfo = [];

    const programLevelsInfo = {};
    const distinctProgramLevelsInfo = [];

    const countriessInfo = {};
    const distinctCountriesInfo = [];

    results.forEach((college) => {
      const collegeKey = college.college_id;
      if (collegesInfo[collegeKey]) {
        collegesInfo[collegeKey].college_count++;
      } else {
        collegesInfo[collegeKey] = {
          college_name: college.college_name,
          college_id: college.college_id,
          college_count: 1,
        };
        distinctCollegeInfo.push(collegesInfo[collegeKey]);
      }
      const programKey = college.program_level;
      if (programLevelsInfo[programKey]) {
        programLevelsInfo[programKey].program_count++;
      } else {
        programLevelsInfo[programKey] = {
          program_name: programLevelsNames[programKey],
          program_level: programKey,
          program_count: 1,
        };
        distinctProgramLevelsInfo.push(programLevelsInfo[programKey]);
      }
      const countryKey = college.country_id;
      if (!countriessInfo[countryKey]) {
        countriessInfo[countryKey] = {
          label: college.college_country,
          value: countryKey,
        };
        distinctCountriesInfo.push(countriessInfo[countryKey]);
      }
    });

    setUniversities(distinctCollegeInfo);
    setProgramLevels(distinctProgramLevelsInfo);
    setLocationOptions(distinctCountriesInfo);
  };

  const updateFilters = (results, selectedUniversities) => {
    // const resetData = universities.map((item) => ({
    //   ...item,
    //   college_count: 0,
    // }));
    // const resetProgramData = programLevels.map((item) => ({
    //   ...item,
    //   program_count: 0,
    // }));
    // const universitiesFilter =
    //   selectedUniversities.length === 0
    //     ? universities
    //     : universities.filter((item) => {
    //         if (selectedUniversities.includes(item.college_id)) {
    //           return true;
    //         }
    //         return false;
    //       });
    // results.map((item) => {
    //   const matchingResult = resetData.find(
    //     (d) => d.college_id === item.college_id
    //   );
    //   if (matchingResult) {
    //     matchingResult.count++;
    //   }
    // });
    // results.map((item) => {
    //   const matchingResult = resetProgramData.find(
    //     (b) => b.program_level === item.program_level
    //   );
    //   if (matchingResult) {
    //     matchingResult.count++;
    //   }
    // });
    // setUniversities(resetData);
    // setProgramLevels(resetProgramData);
  };

  // Modal styles
  const customModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  // Set the root element for the modal
  Modal.setAppElement("#root");

  const handleTuitionChange = (newRange) => {
    // console.log("newRange :>> ", newRange);
    setSelectedTuitionRange(newRange);
  };

  const labels = ["0", "25,000", "50,000", "75,000", "1000000"];

  const customStylesLocation = {
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      border: state.isFocused ? "1px solid #007bff" : "1px solid #ccc",
      borderRadius: "4px",
      boxShadow: state.isFocused
        ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
        : "none",
      "&:hover": {
        border: "1px solid #007bff",
      },
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: "#999",
      textAlign: "left",
    }),
    input: (provided, state) => ({
      ...provided,
      color: "#000",
    }),
  };

  const [locationOptions, setLocationOptions] = useState([]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleFilter = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 900) {
      setIsFilterOpen(true);
      return;
    }
    setIsFilterOpen(!isFilterOpen);
  };

  useEffect(() => {
    const screenWidth = window.innerWidth;
    if (!isFilterOpen && screenWidth >= 900) {
      setIsFilterOpen(true);
    }
  }, [isFilterOpen]);

  const universityData = [
    { name: "University 1 winning", totalCourses: 10 },
    { name: "University 2", totalCourses: 20 },
    { name: "University 3", totalCourses: 15 },
  ];


  const highestQualificationOptions = [
    { value: "", label: "Select...." },
    { value: "Post Graduate", label: "Post Graduate" },
    { value: "Under Graduate", label: "Under Graduate" },
    { value: "Grade 12th or Equivalent", label: "Grade 12th or Equivalent" },
  ];

  const gradingSystem12Options = [
    { value: "", label: "Select...." },
    { value: "Out of 100", label: "Out of 100" },
    { value: "Out of 45", label: "Out of 45" },
    { value: "Out of 10", label: "Out of 10" },
    { value: "Out of 7", label: "Out of 7" },
    { value: "Out of 5", label: "Out of 5" },
    { value: "Out of 4", label: "Out of 4" },
  ];

  const gradingSystemUGOptions = [
    { value: "", label: "Select...." },
    { value: "Out of 100", label: "Out of 100" },
    { value: "Out of 45", label: "Out of 45" },
    { value: "Out of 10", label: "Out of 10" },
    { value: "Out of 7", label: "Out of 7" },
    { value: "Out of 5", label: "Out of 5" },
    { value: "Out of 4", label: "Out of 4" },
  ];

  const englishProficiencyExamOptions = [
    { value: "", label: "Select...." },
    { value: "I dont have this", label: "I dont have this" },
    { value: "I will Provide this later", label: "I will Provide this later" },
    { value: "IELTS", label: "IELTS" },
    { value: "TOEFL", label: "TOEFL" },
    { value: "PTE", label: "PTE" },
    { value: "DET", label: "DET" },
  ];

  const otherExamsOptions = [
    { value: "", label: "Select...." },
    { value: "SAT", label: "SAT" },
    { value: "ACT", label: "ACT" },
    { value: "GRE", label: "GRE" },
    { value: "GMAT", label: "GMAT" },
  ];

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      maxHeight: "300px",
    }),
    option: (provided, state) => ({
      ...provided,
      height: "50px",
      textAlign: "left",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: "#999",
      fontSize: "14px",
      textAlign: "left",
    }),
  };

  useEffect(() => {
    filterResults();
  }, [
    selectedProgramLevels,
    selectedTuitionRange,
    selectedUniversities,
    selectedLocation,
  ]);

  const programLevelsNames = {
    1: "High School (11th - 12th)",
    2: "UG Diploma/ Certificate/ Associate Degree",
    3: "UG",
    4: "PG Diploma/Certificate",
    5: "PG",
    6: "UG+PG (Accelerated) Degree",
    7: "PhD",
    8: "Foundation",
    9: "Short Term Programs",
    10: "Pathway Programs",
    11: "Twinning Programmes (UG)",
    12: "Twinning Programmes (PG)",
    13: "English Language Program",
    14: "Online Programmes / Distance Learning",
    15: "Summer Programs",
  };

  useEffect(() => {
    const universitiesFilter =
      selectedUniversities.length === 0
        ? universities.map((e) => e.college_id)
        : selectedUniversities;

    const locations = Object.keys(selectedLocation).length > 0
      ? [selectedLocation.value]
      : locationOptions.map((e) => e.value);

    const programLevelsInfo = {};
    const distinctProgramLevelsInfo = [];

    results.forEach((college) => {
      const programKey = college.program_level;
      if (programLevelsInfo[programKey]) {
        if (
          universitiesFilter.includes(college.college_id) &&
          college.course_fee >= selectedTuitionRange[0] &&
          college.course_fee <= selectedTuitionRange[1] &&
          locations.includes(college.country_id)
        ) {
          programLevelsInfo[programKey].program_count++;
        }
      } else {
        programLevelsInfo[programKey] = {
          program_name: programLevelsNames[programKey],
          program_level: programKey,
        };

        if (
          universitiesFilter.includes(college.college_id) &&
          college.course_fee >= selectedTuitionRange[0] &&
          college.course_fee <= selectedTuitionRange[1] &&
          locations.includes(college.country_id)
        ) {
          programLevelsInfo[programKey]["program_count"] = 1;
        } else {
          programLevelsInfo[programKey]["program_count"] = 0;
        }
        distinctProgramLevelsInfo.push(programLevelsInfo[programKey]);
      }
    });
    setProgramLevels(distinctProgramLevelsInfo);
  }, [selectedUniversities, selectedTuitionRange, selectedLocation]);

  useEffect(() => {
    const programsFilter =
      selectedProgramLevels.length === 0
        ? programLevels.map((e) => e.program_level)
        : selectedProgramLevels;

    const locations = Object.keys(selectedLocation).length > 0
      ? [selectedLocation.value]
      : locationOptions.map((e) => e.value);

    const collegesInfo = {};
    const distinctCollegeInfo = [];

    results.forEach((college) => {
      const collegeKey = college.college_id;
      if (collegesInfo[collegeKey]) {
        if (
          programsFilter.includes(college.program_level) &&
          college.course_fee >= selectedTuitionRange[0] &&
          college.course_fee <= selectedTuitionRange[1] &&
          locations.includes(college.country_id)
        ) {
          collegesInfo[collegeKey].college_count++;
        }
      } else {
        collegesInfo[collegeKey] = {
          college_name: college.college_name,
          college_id: college.college_id,
        };

        if (
          programsFilter.includes(college.program_level) &&
          college.course_fee >= selectedTuitionRange[0] &&
          college.course_fee <= selectedTuitionRange[1] &&
          locations.includes(college.country_id)
        ) {
          collegesInfo[collegeKey]["college_count"] = 1;
        } else {
          collegesInfo[collegeKey]["college_count"] = 0;
        }

        distinctCollegeInfo.push(collegesInfo[collegeKey]);
      }
    });
    setUniversities(distinctCollegeInfo);
  }, [selectedProgramLevels, selectedTuitionRange, selectedLocation]);

  const filterResults = () => {
    const data = results?.filter((item) => {
      if (
        selectedUniversities.length > 0 &&
        !selectedUniversities.includes(item.college_id)
      ) {
        return false;
      }
      if (
        selectedProgramLevels.length > 0 &&
        !selectedProgramLevels.includes(item.program_level)
      ) {
        return false;
      }
      if (
        (selectedTuitionRange.length > 0 &&
          item.course_fee < selectedTuitionRange[0]) ||
        item.course_fee > selectedTuitionRange[1]
      ) {
        return false;
      }

      if (Object.keys(selectedLocation).length > 0 && !(selectedLocation.value === item.country_id)) {
        return false;
      }
      return true;
    });
    setFilteredResults(data);
  };

  useEffect(() => {
    calculateFilters(results);
  }, [results]);

  return (
    <div className={AdvanceClasses["Advance-Home-filter"]}>
      <div className={AdvanceClasses["Advance-Home-filt-heading"]} onClick={toggleFilter}>
        <FaFilter />
        Filters
      </div>

      <h3 className={AdvanceClasses["Advance-Home-filt-h3"]}>Eligibility</h3>

      {isFilterOpen && (
        <div className={AdvanceClasses["Advance-Home-filter-Content"]}>
          <label className={AdvanceClasses["Advance-Home-label"]} htmlFor="countryOfResidence">
            Select Student
          </label>
          <div style={{ width: "100%", position: "relative" }}>
            <div
              className={AdvanceClasses["Advance-Home-select-student"]}
              onClick={toggleDropdown}
            >
              <p>Select....</p>
              <IoIosArrowDown />
            </div>
            {isDropdownOpen && (
              <div className={AdvanceClasses["dropdown-content"]}>
                <p className={AdvanceClasses["student-register-button"]} onClick={openModal}>
                  <FaPlus />
                  Add New Student
                </p>
              </div>
            )}
          </div>

          <div>
            <label className={AdvanceClasses["Advance-Home-label"]} htmlFor="countryOfResidence">
              Country of Residence
            </label>

            <Select
              options={countryOptions}
              styles={customStyles}
              placeholder="Select...."
            />
          </div>

          <div>
            <label
              className={AdvanceClasses["Advance-Home-label"]}
              htmlFor="highestQualification"
            >
              Highest Qualification Studied
            </label>

            <select
              styles={customStyles}
              id="highestQualification"
              name="highestQualification"
              className={AdvanceClasses["Advance-Home-select"]}
              placeholder="Select...."
              style={{ textAlign: "left", color: "#999", fontSize: "14px" }}
            >
              {highestQualificationOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className={AdvanceClasses["Advance-Home-label"]} htmlFor="gradingSystem12">
              Grading System (12th)
            </label>

            <select
              styles={customStyles}
              id="gradingSystem12"
              name="gradingSystem12"
              className={AdvanceClasses["Advance-Home-select"]}
              placeholder="Select...."
              style={{ textAlign: "left", color: "#999", fontSize: "14px" }}
            >
              {gradingSystem12Options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className={AdvanceClasses["Advance-Home-label"]} htmlFor="score12">
              ( 12th )
            </label>
            <input
              type="text"
              id="score12"
              name="score12"
              className={AdvanceClasses["Advance-Home-select"]}
              placeholder="Enter...."
            />
          </div>

          <div>
            <label className={AdvanceClasses["Advance-Home-label"]} htmlFor="gradingSystemUG">
              Grading System ( UG )
            </label>
            {/* Use regular select for grading system for undergraduate */}
            <select
              id="gradingSystemUG"
              name="gradingSystemUG"
              className={AdvanceClasses["Advance-Home-select"]}
              placeholder="Select...."
              style={{ textAlign: "left", color: "#999", fontSize: "14px" }}
            >
              {gradingSystemUGOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className={AdvanceClasses["Advance-Home-label"]} htmlFor="workExperience">
              ( UG )
            </label>
            <input
              type="text"
              id="workExperience"
              name="workExperience"
              className={AdvanceClasses["Advance-Home-select"]}
              placeholder="Enter...."
            />
          </div>

          <div>
            <label className={AdvanceClasses["Advance-Home-label"]} htmlFor="backlogs">
              Backlogs
            </label>
            <input
              type="text"
              id="backlogs"
              name="backlogs"
              className={AdvanceClasses["Advance-Home-select"]}
              placeholder="Enter...."
            />
          </div>
          <div>
            <label className={AdvanceClasses["Advance-Home-label"]} htmlFor="workExperienceYears">
              Work Experience (Years)
            </label>
            <input
              type="text"
              id="workExperienceYears"
              name="workExperienceYears"
              className={AdvanceClasses["Advance-Home-select"]}
              placeholder="Enter...."
            />
          </div>

          <div>
            <label
              className={AdvanceClasses["Advance-Home-label"]}
              htmlFor="englishProficiencyExam"
            >
              English Proficiency Exam
            </label>
            {/* Use regular select for English proficiency exam */}
            <select
              id="englishProficiencyExam"
              name="englishProficiencyExam"
              className={AdvanceClasses["Advance-Home-select"]}
              placeholder="Select...."
              style={{ textAlign: "left", color: "#999", fontSize: "14px" }}
            >
              {englishProficiencyExamOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className={AdvanceClasses["Advance-Home-label"]} htmlFor="otherExams">
              Other Exams
            </label>
            {/* Use regular select for other exams */}
            <select
              id="otherExams"
              name="otherExams"
              className={AdvanceClasses["Advance-Home-select"]}
              placeholder="Select...."
              style={{ textAlign: "left", color: "#999", fontSize: "14px" }}
            >
              {otherExamsOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className={AdvanceClasses["Advance-Home-label"]} htmlFor="OverallScore">
              Overall Score
            </label>
            <input
              type="text"
              id="OverallScore"
              name="OverallScore"
              className={AdvanceClasses["Advance-Home-select-score"]}
              placeholder="Enter...."
            />
          </div>

          <div className={AdvanceClasses["advance-filter-button"]}>
            <button className={AdvanceClasses["advance-filter-button-one"]}>RESET</button>
            <button className={AdvanceClasses["advance-filter-button-one"]}>APPLY FILTER</button>
          </div>
        </div>
      )}

      <div className={AdvanceClasses["advance-filter-universities"]}>
        <h3 className={AdvanceClasses["advance-filter-universities-header"]}>UNIVERSITIES</h3>
        <div className={AdvanceClasses["advance-filter-university-list"]}>
          {universities.length > 0 ? (
            universities.map((university, index) => (
              <div key={index} className={AdvanceClasses["advance-filter-names"]}>
                <div className={AdvanceClasses["advance-filter-checkbox-name"]}>
                  <input
                    type="checkbox"
                    id={university.college_id}
                    name={`university${index}`}
                    onChange={(e) => handleUniversityCheck(e)}
                  />
                  <label
                    htmlFor={`university${index}`}
                    style={{ fontWeight: "400" }}
                  >
                    {university.college_name.slice(0, 35)}
                  </label>
                </div>
                <div className={AdvanceClasses["advance-filter-textnumber"]}>
                  <span
                    style={{
                      border: "1px solid #ccc",
                      padding: "3px 8px",
                      margin: "5px",
                    }}
                  >
                    {university.college_count}
                  </span>
                </div>
                <br />
              </div>
            ))
          ) : (
            <p>Not found</p>
          )}
        </div>
      </div>

      <div className={AdvanceClasses["advance-filter-universities"]}>
        <h3 className={AdvanceClasses["advance-filter-universities-header"]}>PROGRAM LEVEL</h3>
        <div className={AdvanceClasses["advance-filter-university-list"]}>
          {programLevels.length > 0 ? (
            programLevels.map((program, index) => (
              <div key={index} className={AdvanceClasses["advance-filter-names"]}>
                <div className={AdvanceClasses["advance-filter-checkbox-name"]}>
                  <input
                    type="checkbox"
                    id={program.program_level}
                    name={`program${index}`}
                    onChange={(e) => handleProgramLevelCheck(e)}
                  />
                  <label
                    htmlFor={`program${index}`}
                    style={{ fontWeight: "400" }}
                  >
                    {program.program_name.slice(0, 35)}
                  </label>
                </div>
                <div className={AdvanceClasses["advance-filter-textnumber"]}>
                  <span
                    style={{
                      border: "1px solid #ccc",
                      padding: "3px 8px",
                      margin: "5px",
                    }}
                  >
                    {program.program_count}
                  </span>
                </div>
                <br />
              </div>
            ))
          ) : (
            <p>Not found</p>
          )}
        </div>
      </div>

      <div className={AdvanceClasses["advance-filter-universities"]}>
        <h3 className={AdvanceClasses["advance-filter-universities-header"]}>TUITION FEES</h3>
        <div
          className={AdvanceClasses["advance-filter-university-list"]}
          style={{ margin: "20px", height: "80px" }}
        >
          <Slider
            range
            defaultValue={[0, 100000]}
            min={0}
            max={100000}
            step={5000}
            marks={{
              0: 0,
              20000: 20000,
              40000: 40000,
              60000: 60000,
              80000: 80000,
              100000: 100000,
            }}
            value={selectedTuitionRange}
            allowCross={false}
            onChange={handleTuitionChange}
          />
        </div>
      </div>

      <div className={AdvanceClasses["advance-filter-universities"]}>
        <h3 className={AdvanceClasses["advance-filter-universities-header"]}>LOCATION</h3>
        <div className={AdvanceClasses["advance-filter-university-list"]}>
          <Select
            options={locationOptions}
            styles={customStylesLocation}
            placeholder="Search or select location..."
            onChange={handleLocationChange}
            isSearchable
          />
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        style={customModalStyles}
      >
        <div className={AdvanceClasses["Modal-form"]}>
          <h2>Register New Student</h2>
          <button
            className={AdvanceClasses["modal-close-btn"]}
            onClick={() => setIsModalOpen(false)}
          >
            &times;
          </button>
        </div>

        <form onSubmit={handleSubmit} className={AdvanceClasses["Modal-form-content"]}>
          <div className={AdvanceClasses["modal-form-group"]}>
            <label htmlFor="firstName">First Name *</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className={AdvanceClasses["modal-form-group"]}>
            <label htmlFor="middleName">Middle Name</label>
            <input
              type="text"
              id="middleName"
              name="middleName"
              value={formData.middleName}
              onChange={handleInputChange}
            />
          </div>
          <div className={AdvanceClasses["modal-form-group"]}>
            <label htmlFor="lastName">Last Name *</label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className={AdvanceClasses["modal-form-group"]}>
            <label htmlFor="mobileNumber">Mobile Number *</label>
            <input
              type="text"
              id="mobileNumber"
              name="mobileNumber"
              value={formData.mobileNumber}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className={AdvanceClasses["modal-form-group"]}>
            <label htmlFor="emailAddress">Email Address *</label>
            <input
              type="email"
              id="emailAddress"
              name="emailAddress"
              value={formData.emailAddress}
              onChange={handleInputChange}
              required
            />
          </div>
          <button type="submit" className={AdvanceClasses["modal-button"]}>
            Submit
          </button>
        </form>
      </Modal>
    </div>
  );
}

export default HomeFilter;
